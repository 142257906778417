import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, FunctionField, List, TextField, TopToolbar, useListContext, useRefresh } from "react-admin"
import { CreateBundleButton } from "./components/buttons/CreateBundleButton";
import { ChangeColorButton } from "./components/buttons/ChangeColorButton";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { Box, Checkbox, Chip, makeStyles, Tooltip } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import ErrorIcon from "@material-ui/icons/Error";
import { AddNotesButton } from "./components/buttons/AddNotesButton";
import { LinkContractsButton, UnlinkContractsButton } from "./components/buttons/LinkContractsButton";
import { RemoveBundleButton } from "./components/buttons/RemoveBundleButton";
import { SetContractTypeButton } from "./components/buttons/SetContractTypeButton";
import EntityLink from "../utils/components/EntityLink";
import { useTranslate } from "ra-core";
import DefaultPagination from "../utils/DefaultPagination";
import { avisFilter, avisFilterDefaultValues } from "./components/AvisFilter";
import { DeleteButton } from "../utils/components/Buttons/DeleteButton";
import { defaultButtonStyle } from "../utils/components/styles/ButtonStyles";

const INACTIVITY_THRESHOLD = 30000; // 60 Sekunden Inaktivität

const ContractAvisList = ( props ) => {
  const refresh = useRefresh();
  const inactivityTimer = useRef(null);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const resetTimer = () => {
      if (inactivityTimer.current) clearTimeout(inactivityTimer.current);
      inactivityTimer.current = setTimeout(async () => {
        if (!popupOpen) {
          console.debug("User inactive, refreshing list");
          refresh();
          resetTimer()
        } else {
          console.debug("Popup open, not refreshing list");
          resetTimer()
        }
      }, INACTIVITY_THRESHOLD);
    };

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    resetTimer();

    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      if (inactivityTimer.current) clearTimeout(inactivityTimer.current);
    };
  }, [refresh, popupOpen]);

  return <List
    pagination={<DefaultPagination/>}
    filters={avisFilter}
    filterDefaultValues={avisFilterDefaultValues}
    hasEdit={false}
    hasShow={false}
    syncWithLocation={true}
    hasCreate={false}
    exporter={false}
    sort={{ field: 'contract.datum', order: 'ASC' }}
    filter={{ hideLinkedAvis: true }}
    perPage={parseInt( process.env.REACT_APP_LIST_ITEMS )}
    {...props}
  >
    <AvisListView {...props} setPopupOpen={setPopupOpen} />
  </List>
};

export function translateStatus( status ) {
  const statusMap = {
    'pending': 'Offen',
    'first_contact': 'Erstkontakt',
    'confirmed': 'Bestätigt',
    'awaiting_response': 'Rückmeldung ASP abwarten',
    'awaiting_dispo': 'Rückmeldung Dispo',
    'doing': 'In Bearbeitung'
  }

  return statusMap[ status ] || status;
}

const useStyles = makeStyles( theme => ( {
  root: {
    width: "100%",
    margin: "1rem auto",
  },
  tableContainer: {
    // overflowX: "initial",
    // overflowY: "auto",
    // maxHeight: "calc(100vh - 250px)",
  },
  actionBar: {
    position: "absolute",
    top: 0,
    transform: "translateY(-100%)",
    color: "#2196F3",
    background: "rgb(231, 243, 253)",
    justifyContent: "space-between",
    width: "100%",
    zIndex: "1000",
    display: "flex",
    alignItems: "center",
    height: "64px",
    transition: "all 0.3s",
    overflow: "hidden"
  },
  tableHeadCell: {
    padding: "6px 24px 6px 16px",
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    }
  },
  responsiveBox: {
    padding: "0 1rem 0 0",
    fontSize: "0.875rem",
    maxWidth: "1280px",
    [ theme.breakpoints.down( 'lg' ) ]: {
      maxWidth: '860px',  // Set the max-width for small screens
    },
  },
  gridContainer: {
    borderBottom: '1px solid rgb(224,224,224)',
    alignItems: 'center',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
} ) )

const DeselectTextLink = ( { onClick, listSize } ) => {
  let text = "Ein Element ausgewählt";
  if( listSize > 1 ) {
    text = `${listSize} Elemente ausgewählt`;
  }
  return <Box display={"flex"} alignItems={"center"}>
    <Button onClick={onClick} children={<ClearIcon/>} variant="text"/>
    {text}
  </Box>
}

const groupByBundle = ( contractList ) => {
  const groupedList = []

  // sort grouped list
  contractList.sort( ( a, b ) => {
    // compare getDate( a ) and getDate( b )
    if( getDate( a ) < getDate( b ) ) {
      return -1
    }
    if( getDate( a ) > getDate( b ) ) {
      return 1
    }

    return 0
  })

  for( const contract of contractList ) {
    if( !contract ) {
      continue
    }

    if( !contract.bundle?.id ) {
      groupedList.push( contract )
      continue
    }

    const id = contract.bundle.id
    let group = groupedList.find( ( element ) => element.groupId === id )
    if( !group ) {
      const group = {
        groupId: id,
        contracts: [contract]
      }
      groupedList.push( group );
      continue
    }

    group.contracts.push( contract )
  }

  const flatGroupedList = []
  for( const item of groupedList ) {
    if( item.contracts ) {
      for( const contract of item.contracts ) {
        flatGroupedList.push( contract )
      }
    } else {
      flatGroupedList.push( item )
    }
  }

  return flatGroupedList
}

const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

function getDate( record ) {
  let d

  if( record?.coordination === "swap" || record?.coordination === "delivery" ) {
    d = record?.contractDetails?.deliveryDate
  } else if( record?.coordination === "pickup" ) {
    d = record?.contractDetails?.pickupDate
  } else if( record?.coordination === "pickup_swap" ) {
    d = record?.contractDetails?.pickupDate
  } else if( record.contractType === "PICKUP" ) {
    d = record.contractDetails.pickupDate
  } else {
    d = record.contractDetails.deliveryDate
  }

  const [day, month, year] = d.split(".")
  return new Date( `${year}-${month}-${day}` )
}

const AvisListView = ( { setPopupOpen, ...props } ) => {
  const { ids, data } = useListContext();
  const classes = useStyles();
  const [selectedContracts, setSelectedContracts] = useState( new Set() );

  const records = groupByBundle( ids.map( ( id ) => data[ id ] ) );

  const listItems = records.map( ( record ) => {
    return <AvisListItem record={record} key={record.id} selectedContracts={selectedContracts} setSelectedContracts={setSelectedContracts} setPopupOpen={setPopupOpen} {...props}/>
  } )

  const getHeight = ( size ) => {
    if( size === 0 ) {
      return { height: '0' }
    }

    return {}
  }

  return <Box>
    <Box className={classes.actionBar} style={getHeight( selectedContracts.size )}>
      <DeselectTextLink onClick={() => setSelectedContracts( new Set() )} listSize={selectedContracts.size}/>

      <Box className={classes.responsiveBox}>
        {selectedContracts.size > 0 && <Box display={"flex"}>
          <CreateBundleButton selectedContracts={selectedContracts} setSelectedContracts={setSelectedContracts}/>
          <ChangeColorButton displayLabel={true} selectedContracts={selectedContracts} setSelectedContracts={setSelectedContracts}/>
        </Box>}
      </Box>
    </Box>
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeadCell} width={"25px"} style={{ padding: "6px 0 6px 12px" }}></TableCell>
            <TableCell className={classes.tableHeadCell}>Kunde</TableCell>
            <TableCell className={classes.tableHeadCell}>Auftragsnummer</TableCell>
            <TableCell className={classes.tableHeadCell}>Kennzeichen</TableCell>
            <TableCell className={classes.tableHeadCell}>Fahrer</TableCell>
            <TableCell className={classes.tableHeadCell}>Abstimmung</TableCell>
            <TableCell className={classes.tableHeadCell}>Stellplatz</TableCell>
            <TableCell className={classes.tableHeadCell}>Datum</TableCell>
            <TableCell className={classes.tableHeadCell}>Uhrzeit</TableCell>
            <TableCell className={classes.tableHeadCell}>Status</TableCell>
            <TableCell className={classes.tableHeadCell}>Bemerkung</TableCell>
            <TableCell className={classes.tableHeadCell}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listItems}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
}

const ParkingSpaceListField = ( { record } ) => {
  if( record?.parkingSpace && record?.parkingSpaceNotificationScheduled ) {
    return 'Ja'
  }

  if( record?.contractDetails?.pickupLocationIsParkingSpace ) {
    return 'Offen'
  }

  return '';
}

const AvisListItem = ( { record, setPopupOpen, ...props } ) => {
  const classes = useStyles();
  const { selectedContracts, setSelectedContracts, ...rest } = props;

  const handleCheckboxChange = useCallback( ( e ) => {
    const newSelectedContracts = new Set( selectedContracts );
    if( e.target.checked ) {
      newSelectedContracts.add( record[ '@id' ] );
    } else {
      newSelectedContracts.delete( record[ '@id' ] );
    }
    setSelectedContracts( newSelectedContracts );
  }, [record, selectedContracts, setSelectedContracts] );

  return <TableRow class={classes.tableRow}>
    <TableCell style={{ padding: "6px 0 6px 12px" }}>
      <Checkbox onChange={handleCheckboxChange} checked={selectedContracts.has( record[ '@id' ] )} style={{ padding: 0 }}/>
    </TableCell>

    <TableCell>
      <TextField source={"contractDetails.customer"} label={"Kunde"} record={record}/>
    </TableCell>

    <TableCell>
      <Box display={"flex"} alignItems={"center"}>

        {record?.bundle?.id && <Tooltip title={`Gruppe ${record.bundle?.groupName}`} key={`${record.bundle?.groupName}`} arrow interactive>
          <Chip
            label={record.bundle?.groupName}
            style={{ backgroundColor: "orange", color: "white", marginRight: "2px" }}
            size="small"
          />
        </Tooltip>}

        <EntityLink record={record.contractDetails.contract} style={{ minWidth: 'auto' }} title={`${record.contractDetails.orderNumber} in AE anzeigen`} target={"_blank"}>
          <TextField source={"contractDetails.orderNumber"} record={record} {...props} />
        </EntityLink>

        {record?.linkedAvis && <EntityLink record={record?.linkedAvis?.contractDetails?.contract}
                                           style={{ minWidth: 'auto' }}
                                           title={`${record?.linkedAvis?.contractDetails?.auftragsnummer} in AE anzeigen`}
                                           target={"_blank"}>
          <TextField record={record} source={"linkedAvis.contractDetails.orderNumber"}/>
        </EntityLink>}
      </Box>
    </TableCell>

    <TableCell>
      <TextField source={"contractDetails.licensePlate"} label={"Kennzeichen"} sortBy={"kennzeichen"} record={record} {...props} />
      {record?.linkedAvis && <> / <TextField source={"linkedAvis.contractDetails.licensePlate"} label={"Kennzeichen"} sortBy={"kennzeichen"} record={record} {...props} /></>}
    </TableCell>

    <TableCell>
      <TextField source={"contractDetails.driver"} label={"Fahrer"} sortBy={"abholer.kuerzel"} record={record} {...props} />
    </TableCell>

    <TableCell>
      <ListFieldCoordination record={record} label={"Abstimmung"} {...props} />
    </TableCell>

    <TableCell>
      <ParkingSpaceListField record={record}/>
    </TableCell>

    <TableCell>
      <FunctionField label={"Datum"} record={record} render={record => {
        if( record.contractDetails.flex ) {
          return ''
        }

        const d = getDate( record )
        return (typeof d === 'string') ? d : formatDate( d )
      }}/>
    </TableCell>


    <TableCell>
      <FunctionField label={"Uhrzeit"} record={record} render={record => {
        if( record.contractDetails.flex ) {
          return ''
        }

        if( record?.coordination === "swap" || record?.coordination === "delivery" ) {
          return record?.contractDetails?.deliveryTime
        }

        if( record?.coordination === "pickup" ) {
          return record?.contractDetails?.pickupTime
        }

        if( record?.coordination === "pickup_swap" ) {
          return record?.contractDetails?.pickupTime+" / "+record?.contractDetails?.deliveryTime
        }

        if( record.contractType === "PICKUP" ) {
          return record.pickupTimeFrom+" - "+record.pickupTimeTo
        }

        if( record.contractType === "DELIVERY" ) {
          return record.deliveryTimeFrom+" - "+record.deliveryTimeTo
        }
      }}/>
    </TableCell>

    <TableCell style={{ backgroundColor: record?.color ?? '' }}>
      {translateStatus( record?.status ?? 'pending' )}
    </TableCell>

    <TableCell style={{ backgroundColor: record?.color ?? '' }}>
      <Box display={"flex"} alignItems={"center"}>
        {record?.importantNote && <ErrorIcon style={{ color: 'red' }}/>}&nbsp;
        <TextField source="note" label={"Bemerkung"} record={record} sortable={false}/>
      </Box>
    </TableCell>

    <TableCell style={{ backgroundColor: record?.color ?? '' }}>
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <AddNotesButton record={record} setPopupOpen={setPopupOpen}/>
        <LinkContractsButton record={record}/>
        <UnlinkContractsButton record={record}/>
        <ChangeColorButton record={record}/>
        <RemoveBundleButton record={record}/>
        <DeleteButton record={record} label={""} style={defaultButtonStyle} />
      </Box>
    </TableCell>
  </TableRow>
}

const AvisBulkActionList = () => {
  return <>
    <CreateBundleButton/>
    <ChangeColorButton displayLabel={true}/>
  </>
}

const ListFieldCoordination = ( { record } ) => {
  const translate = useTranslate();

  if( record?.coordination ) {
    return translate( `avis.coordination.${record?.coordination}` )
  }

  if( record?.linkedAvis ) {
    return translate( 'avis.coordination.swap' )
  }

  if( record?.contractType ) {
    return translate( 'avis.coordination.'+record.contractType.toLowerCase() )
  }

  return <SetContractTypeButton record={record}/>
}

export default ContractAvisList;
