import * as React from "react";
import { useState } from "react";
import { Create, Edit, FormWithRedirect, PasswordInput as DefaultPasswordInput, SaveButton, SimpleForm, TextInput, TopToolbar } from "react-admin";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { EditButton } from "../utils/components/Buttons";
import BooleanInput from "../utils/components/BooleanInput";
import { CustomerReferenceInput } from "../customers/components/CustomerReferenceInput";
import { CustomerDepartmentReferenceInput } from "../customerDepartment/components/CustomerDepartmentReferenceInput";
import { UserGroupReferenceArrayInput } from "../userGroups/components/UserGroupReferenceInput";
import { Box, Card, CardContent, CardHeader, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { InputContainer, ParagraphBox } from "../utils/components/FormElements";
import { PasswordInput } from "../utils/components/PasswordWithSuggestionInput";

const EditActions = ( props ) => {
  const entity = {
    '@type': 'Customer',
    '@id': props.customer !== null && typeof props.customer === "object" && props.customer.hasOwnProperty( "@id" ) ? props.customer[ "@id" ] : props.customer
  }

  return <TopToolbar>
    {props.customer ? <EditButton
      entity={entity}
      label={"Zurück"}
      children={<ChevronLeft/>}
      redirectTo={( resource, id, data ) => {
        return `/customers/${entity[ '@id' ]?.replaceAll( '/', '%2F' )}/3`;
      }}
    /> : null}
  </TopToolbar>
}

const useStyles = makeStyles(theme => ( {
  card: {
    margin: "0.5rem",
    padding: "1rem"
  },
  cardFullWidth: {
    margin: "0.5rem",
    padding: "1rem",
    overflow: "visible",
    gridColumn: "span 2",
    [theme.breakpoints.down('lg')]: {
      gridColumn: "span 1",
    },
  },
  cardActionButtons: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem",
    alignSelf: "center",
    margin: "0.5rem",
    width: "fit-content",
  },
  formContainer: {
    display: "grid",
    position: "relative",
    height: "100%",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  }
}))

const EntityForm = props => {
  const { isCreate = false } = props
  const classes = useStyles()
  const { setCustomer } = props

  setCustomer( props.record?.customer )
  const customer = props.record?.customer !== null && typeof props.record?.customer === "object" && props.record?.customer.hasOwnProperty( "@id" ) ? props.record?.customer[ "@id" ] : props.record?.customer

  return <FormWithRedirect {...props}
                           redirect={`/customers/${customer?.replaceAll( '/', '%2F' )}/3`}
                           render={( { handleSubmitWithRedirect, pristine, saving } ) => {
    return <form className={classes.form}>
      <Box className={classes.formContainer}>
        <Card className={classes.card}>
          <CardHeader title={"Konto"} />
          <CardContent>
            <ParagraphBox>
              <InputContainer left>
                <BooleanInput source={"active"} label={"Aktiv"} defaultValue={true} helperText={false} fullWidth/>
              </InputContainer>
              <InputContainer right>
                <BooleanInput source={"hide"} label={"Ausgeblendet"} defaultValue={false} helperText={false} fullWidth/>
              </InputContainer>
            </ParagraphBox>
            <ParagraphBox>
              <InputContainer left>
                <TextInput source={"username"} label={"Nutzername"} helperText={false} required={true} fullWidth/>
              </InputContainer>
              <InputContainer center>
                <PasswordInput source={"plainPassword"} label={"Neues Passwort"} helperText={false} initiallyVisible={true} fullWidth/>
              </InputContainer>
              <InputContainer right>
                <DefaultPasswordInput source={"initialPassword"} disabled={true} helperText={false} label={"Initial-Password"} fullWidth/>
              </InputContainer>
            </ParagraphBox>

            <ParagraphBox>
              <InputContainer left>
                <TextInput source={"name"} label={"Name"} helperText={false} fullWidth/>
              </InputContainer>
              <InputContainer center>
                <TextInput source={"phone"} label={"Telefon"} helperText={false} fullWidth/>
              </InputContainer>
              <InputContainer right>
                <TextInput source={"email"} label={"Email Adresse"} helperText={false} fullWidth/>
              </InputContainer>
            </ParagraphBox>
          </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardHeader title={"Kundendaten"} />
          <CardContent>
            <ParagraphBox>
              <InputContainer left>
                <TextInput source={"customerDepartmentName"} label={"Abteilung"} helperText={false} fullWidth/>
              </InputContainer>
              <InputContainer right>
                <TextInput source={"position"} label={"Position"} helperText={false} fullWidth/>
              </InputContainer>
            </ParagraphBox>

            <ParagraphBox>
              <InputContainer left>
                <CustomerDepartmentReferenceInput source={"department"} label={"Abteilung / Filiale"} customer={props.record.customer} helperText={false}/>
              </InputContainer>
              <InputContainer right>
                <CustomerReferenceInput source={"customer"} label={"Kunde"} disabled helperText={false}/>
              </InputContainer>
            </ParagraphBox>
            <UserGroupReferenceArrayInput source={"userGroups"} label={"Gruppen"} helperText={false}/>
          </CardContent>
        </Card>

        <Card className={classes.cardActionButtons}>
          {isCreate ? <SaveButton
            handleSubmitWithRedirect={ handleSubmitWithRedirect }
            pristine={ pristine }
            saving={ saving }
            mutationMode="pessimistic"
            label="Nutzer erstellen" /> : <>
          <SaveButton
            handleSubmitWithRedirect={ handleSubmitWithRedirect }
            pristine={ pristine }
            redirect={false}
            saving={ saving }
            mutationMode="pessimistic"
            label="Speichern" />
          &nbsp;

          <SaveButton
            handleSubmitWithRedirect={ handleSubmitWithRedirect }
            pristine={ pristine }
            saving={ saving }
            mutationMode="pessimistic"
            label="Speichern und schließen" />
          </>}
        </Card>

      </Box>
    </form>
  }}/>
}

const CustomToolbar = props => {
  const [debouncing, setDebouncing] = useState( true );

  setTimeout( () => {
    setDebouncing( false );
  }, 3000 );

  return <Toolbar {...props} style={{ backgroundColor: "#F5F5F5", marginTop: "1rem" }}>
    <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
      <SaveButton {...props} saving={debouncing}/>
    </Box>
  </Toolbar>
};

const EntityTitle = ( { record } ) => {
  return <span>Nutzer-Konto {record ? `"${record.username}"` : ''}</span>;
}

const EntityEdit = props => {
  const [customer, setCustomer] = useState( null );
  return <Edit actions={<EditActions customer={customer} {...props} />} title={<EntityTitle/>} {...props} component={"div"}>
    <EntityForm setCustomer={setCustomer}/>
  </Edit>
};

const EntityCreate = props => {
    const [customer, setCustomer] = useState( null );
    return <Create actions={<EditActions customer={customer} {...props} />} {...props} title={"Neuen Eintrag anlegen"} component={"div"}>
      <EntityForm setCustomer={setCustomer} isCreate={true} {...props} style={{ width: '100%' }}/>
    </Create>
  }
;


export { EntityEdit, EntityCreate };
